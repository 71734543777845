import { WebIM } from '@/im/WebIM'
import store from '@/store'
import { MessageType, MessageStatus } from '@/utils/constant'
import * as types from '@/store/mutation-types'
import { getItem, setItem } from '@/utils/cache'
import { isDom } from '@/utils'
const CHAT_TYPE = 'singleChat'
const STORAGE_MESSAGE_KEY = 'MESSAGE_DICT'
export class Chat {
  toUsername = ''
  fromUsername = ''
  password = ''
  store = null
  WebIM = null

  constructor(fromUsername, password, toUsername) {
    this.WebIM = WebIM
    this.store = store
    this.setUsername(fromUsername,password, toUsername)
    this.addListener()
  }

  get chatId() {
    return `${this.fromUsername}_${this.toUsername}`
  }

  setUsername(from = '',password='', to = '') {
    this.fromUsername = from
    this.password = password
    this.toUsername = to
  }

  login() {
    return new Promise((resolve, reject) => {
      this.WebIM.conn.open({
        apiUrl: this.WebIM.config.apiURL,
        user: this.fromUsername,
        pwd: this.password,
        appKey: this.WebIM.config.appkey,
        success: (res) => {
          console.log('login success', res)
          this.store.commit(types.getMutationType(types.SET_LOGIN_STATUS), true)
          resolve(res)
        },
        error: function() {
          reject('login error')
        }
      })
    })
  }

  // 退出环信
  logout() {
    this.WebIM.conn.close()
  }

  /**
   * 发送消息统一接口
   * @param type 消息类型
   * @param message 消息对象，包含：msg,time字段
   * @returns {Promise<any>}
   */
  sendMessage(type, message) {
    console.log(type,'sendType')
    console.log(message,'新添加的message')
    if (type === MessageType.TEXT) {
      return this.sendTextMessage(message)
    } else if (type === MessageType.IMAGE) {
      console.log('is dom', isDom(message.msg))
      return this.sendImageMessage(message)
    }
  }

  /**
   * 发送文本消息
   * @param msg 消息内容
   * @param time 时间: 可以不传时间：可不传（掉线后重新登录发送需要的字段）
   * @param replace 是否替换空白、换行符号
   * @param status 是否结束聊天，医生端使用
   * @param isPrescription 是否是药方
   * @param confrId 视频会议id
   * @returns {Promise<any>}
   */
  sendTextMessage({ msg, time,fzlsh, replace = true, status, isPrescription = false, confrId = '' }) {
    // 保存当前发送时间
    time = time || Date.now()
    return new Promise((resolve, reject) => {
      if (!msg) {
        reject('empty message')
      }

      if (replace) {
        msg = msg.replace(/^[\s\n\r]+|[\s\n\r]+$/g, '')
      }

      const id = this.WebIM.conn.getUniqueId()
      // eslint-disable-next-line new-cap
      const msgObj = new this.WebIM.message(MessageType.TEXT, id)
      const ext = {
        status: status || '',
        isPrescription,
        confrId,
        fzlsh
      }
      msgObj.set({
        msg,
        to: this.toUsername,
        chatType: CHAT_TYPE,
        roomType: false,
        ext,
        success: () => {
          const message = this.normalizeSendMessage({ msg, id, time, ext }, MessageType.TEXT)
          this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_LIST), { message, chatModel: this })
          resolve(message)
        },
        fail: function(e) {
          console.log('Send private text error', e)
          reject(e)
        }
      })
      this.WebIM.conn.send(msgObj.body)
    })
  }

  /**
   * 发送图片信息
   * @param msg input对象
   * @param time 时间：可不传（掉线后重新登录发送需要的字段）
   * @param url 自己服务器的图片地址
   * @returns {Promise<any>}
   */
  sendImageMessage({ msg, time,fzlsh, url }) {
    console.log('send image',fzlsh)
    // mid用于发送成功后删除暂时显示的图片
    // const mid = this.saveWaitSendMessage(msg, MessageType.IMAGE, url)
    try {
      // console.log(msg,'发送图片')
      time = time || Date.now()
      return new Promise((resolve, reject) => {
        if (isDom(msg)) {
          msg = this.getFileUrl(msg)
        }

        if (!msg.filename) {
          reject('empty message')
        }
        const id = this.WebIM.conn.getUniqueId()
        // eslint-disable-next-line new-cap
        const msgObj = new this.WebIM.message(MessageType.IMAGE, id)
        const ext = {

          fzlsh:fzlsh
        }
        msgObj.set({
          apiUrl: this.WebIM.config.apiURL,
          file: msg,
          ext,
          to: this.toUsername,
          roomType: false,
          onFileUploadError: (error) => {
            console.log('图片上传失败', error.data)
            reject(error.data)
          },
          onFileUploadComplete: (data) => {
            const message = this.normalizeSendMessage({ msg: data, url, id,ext,time }, MessageType.IMAGE)
            this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_LIST), { message, chatModel: this })
          },
          success: (id, mid) => {
            resolve({ id, mid })
          },
          fail: (e) => {
            console.log('fail', e)
          }
        })
        this.WebIM.conn.send(msgObj.body)
      })
    } catch (e) {
      console.log('send error', e)
    }

  }

  /**
   * 获取环信的图片信息
   * @param file input dom元素
   * @returns {file}
   */
  getFileUrl(file) {
    return this.WebIM.utils.getFileUrl(file)
  }

  saveWaitSendMessage(msg, type, url) {
    const msgList = [...this.store.getters['chat/currentWaitingSendMsgList']]

    const message = this.normalizeWaitSendMessage({ msg, url }, type)
    msgList.push(message)

    this.store.commit(types.getMutationType(types.SET_WAITING_SEND_MSG_LIST), {
      chatModel: this,
      msgList
    })

    return message.mid
  }

  /**
   * 向对方发送消息已读回执
   * @param message
   */
  sendReadMsg(message) {
    const bodyId = message.id || message.mid
    // eslint-disable-next-line new-cap
    const msg = new this.WebIM.message(MessageStatus.READ, this.WebIM.conn.getUniqueId())
    msg.set({
      id: bodyId,
      to: message.from
    })
    this.WebIM.conn.send(msg.body)
  }

  /**
   * 构造本地存储的发送消息的结构
   * @param msg 消息内容
   * @param id 消息id
   * @param time 发送时间
   * @param type 消息类型
   * @param url 自己服务器的图url
   * @param ext 处理消息的额外信息
   * @returns {{msg: *, chatId: string, bySelf: boolean, mid: string, from: string, time: (*|number), type: *, chatType: string, status: string}}
   */
  normalizeSendMessage({ msg, id, url, time, ext }, type) {
    const ret = {
      // 将id转成字符串，方便和环信返回字符串的id对比
      mid: id + '',
      chatType: CHAT_TYPE,
      chatId: this.chatId,
      bySelf: true,
      status: MessageStatus.SENT,
      type,
      from: this.fromUsername,
      to: this.toUsername,
      msg,
      time: time || Date.now(),
      ext
    }

    if (type === MessageType.IMAGE) {
      ret.time = time || msg.timestamp
      ret.msg = url || `${msg.uri}/${msg.entities[0].uuid}`
    }

    return ret
  }

  /**
   * 构造待发送消息类型
   * @param msg 消息内容
   * @param url 图片信息的base64url
   * @param type 消息类型
   * @returns {{msg: *, chatId: string, bySelf: boolean, mid: string, time: number, type: *, chatType: string, status: string}}
   */
  normalizeWaitSendMessage({ msg, url }, type) {
    const time = Date.now()
    const ret = {
      mid: time + '',
      chatType: CHAT_TYPE,
      chatId: this.chatId,
      bySelf: true,
      status: MessageStatus.SENDING,
      from: this.fromUsername,
      to: this.toUsername,
      type,
      msg,
      time
    }

    if (type === MessageType.IMAGE) {
      ret.url = url
    }

    return ret
  }

  /**
   * 构造本地存储的接收到的消息的结构
   * @param from 发送方
   * @param data 消息内容
   * @param url 图片的url
   * @param time 时间
   * @param ext 扩展信息，发送时传递的参数
   * @param id
   * @param type 消息类型
   * @returns {{msg: *, chatId: string, bySelf: boolean, mid: *, from: *, time: number, type: *, chatType: string}}
   */
  normalizeReceiveMessage({ from, data, url, time, ext = {}, id }, type) {
    const chatId = `${this.fromUsername}_${from}`
    const ret = {
      chatType: CHAT_TYPE,
      chatId,
      msg: data,
      bySelf: false,
      from,
      to: this.fromUsername,
      mid: id,
      type,
      time: +time,
      ext
    }

    if (type === MessageType.IMAGE) {
      const { width, height } = ext
      ret.width = width
      ret.height = height
      ret.msg = ext.url || url
    }

    return ret
  }

  // 从localstorage中读取所有的消息
  getMessageDict() {
    return getItem(STORAGE_MESSAGE_KEY, {})
  }

  // 将消息存入缓存
  setMessageDict(msgDict) {
    setItem(STORAGE_MESSAGE_KEY, msgDict)
  }

  // 注册监听回调
  addListener() {
    const _this = this
    this.WebIM.conn.listen({
      // 连接成功回调
      onOpened() {
        console.log('login success')
        _this.store.commit(types.getMutationType(types.SET_LOGIN_STATUS), true)
      },
      // 掉线的回调
      onClosed() {
        console.log('close im')
        _this.WebIM.conn.close()
        _this.store.commit(types.getMutationType(types.SET_LOGIN_STATUS), false)
      },
      // 收到文本消息
      onTextMessage(msg) {
        console.log('receive text msg', msg)
        const message = _this.normalizeReceiveMessage(msg, MessageType.TEXT)
        _this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_LIST), { message, chatModel: _this })

        // 收到这条消息，表示咨询结束
        // message.data === '您的本次咨询已经结束，系统将关闭本次聊天。若您还有疑问，也可以再次发起咨询。祝您早日康复，谢谢。'
        const { from, ext } = msg
        if (ext && ext.status === 'finished') {
          _this.store.commit(types.getMutationType(types.SET_FINISH_STATUS), {
            chatId: `${_this.fromUsername}_${from}`,
            finished: true
          })
        }
      },
      // 收到图片消息
      onPictureMessage(msg) {
        console.log('receive picture msg', msg)
        const message = _this.normalizeReceiveMessage(msg, MessageType.IMAGE)
        _this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_LIST), { message, chatModel: _this })
      },
      // 本机网络连接成功
      onOnline() {
        console.log('onOnline 网络已连接')
      },
      // 本机网络掉线
      onOffline: function() {
        console.log('onOffline 网络已断开')
      },
      // 失败回调
      onError(message) {
        console.log('error', message)
        if (message.type === 0) {
          console.log('请输入账号密码')
        } else if (message.type === 28) {
          console.log('未登陆')
          _this.store.commit(types.getMutationType(types.SET_LOGIN_STATUS), false)
        }
      },
      // 收到消息送达服务器回执
      onReceivedMessage(message) {
        console.log('onReceivedMessage', message)
        _this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_MID), { message, chatModel: _this })
        message.status = MessageStatus.SENT
        _this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_STATUS), { message, chatModel: _this })
      },
      // 收到消息送达客户端回执
      onDeliveredMessage(message) {
        console.log('onDeliveredMessage', message)
      },

      // 收到消息已读回执
      onReadMessage(message) {
        console.log('onReadMessage', message)
        message.status = MessageStatus.READ
        _this.store.commit(types.getMutationType(types.UPDATE_MESSAGE_STATUS), { message, chatModel: _this })
      }
    })
  }
}

/**
 * 初始化环信，挂载监听回调
 * @param fromUsername 自己的环信账号 初始化时可以为空
 * @param toUsername 对方的环信账号，初始化时可以为空
 * @returns {Chat}
 */
export function initChat(fromUsername,password, toUsername) {
  const chatModel = new Chat(fromUsername, password, toUsername)
  // 从localstorage中读取消息列表数据
  const messageDict = chatModel.getMessageDict()
  store.commit(types.getMutationType(types.SET_MESSAGE_DICT), { messageDict, chatModel })

  return chatModel
}
